<template>
    <modal class="foodModel" :isShow="isShow">
        <div class="header">选择菜品绑定厨师</div>
        <div class="modal-body">
            <div class="li-box">
                厨师名称：{{name}}
            </div>
            <div class="search-box">
                <key-board-input class="search"
                    type="text" v-model="searchText" placeholder="菜品模糊检索"
                    isBoard isKeyEnterClose isBlurClose
                    :keyOptions="{isKeyDownEnter:true}"
                ></key-board-input>
            </div>
            <div class="check">
                <el-tree
                    ref="tree"
                    :data="data"
                    show-checkbox
                    :default-expand-all="!foods?.length>100"
                    node-key="id"
                    :filter-node-method="filterNode"
                    highlight-current>
                </el-tree>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()"> 确认</button>
        </div>
        
    </modal>
</template>

<script>
// 选择菜品
export default {
    name:'foodModel',
    props:{
        isShow:Boolean,
        //菜品
        foods:{
            type:Array
        },
        name:{
            type:String,
        }
    },
    data(){
        return {
            searchText:null
        }
    },
    computed:{
        data(){
            let list=[];
            let keyChildren={}
            this.foods?.forEach((it)=>{
                if(!keyChildren[it.SubCategory_ID]){
                    keyChildren[it.SubCategory_ID]=[];
                    list.push({
                        id: "sub_"+it.SubCategory_ID,
                        label: it.SubCategory_Name,
                        children:keyChildren[it.SubCategory_ID]
                    })
                }

                keyChildren[it.SubCategory_ID].push({
                    id: it.Food_ID,
                    label: it.Food_Name,
                    Food_Code:it.Food_Code,
                    Food_Name:it.Food_Name,
                    Food_Size:it.Food_Size,
                    Food_PinYin:it.Food_PinYin,
                    SubCategory_ID:it.SubCategory_ID,
                    SubCategory_Name:it.SubCategory_Name
                });
            })
            return list;
        }
    },
    watch:{
        isShow(newVal){
            if(newVal){
                this.$refs.tree.setCheckedKeys([]);
            }
        },
        searchText(newVal){
            this.$refs.tree?.filter(newVal);
        }
    },
    mounted(){
        
        this.$nextTick(()=>{
            //this.loadData();
        });
    },
    methods:{
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            let nodes=this.$refs.tree.getCheckedNodes();
            let AutoIDs=[];
            nodes.forEach((it)=>{
                if(!it.children){
                    AutoIDs.push(it.id);
                }
            })
            this.$emit("confirm",AutoIDs)
        },
        /**搜索 */
        filterNode(value, data){
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.Food_Code?.toLocaleUpperCase().includes(value) || data.Food_Name?.toLocaleUpperCase().includes(value) || data.Food_PinYin?.toLocaleUpperCase().includes(value);
        }
        
    }
}
</script>

<style lang="scss">
@import './foodModel.scss'
</style>